














































































































































































































import Base from '@/mixins/Base.vue';
import { INotification, INotificationsResponse } from '@/interfaces/notification';
import { IPagination } from '@/interfaces/pagination';

const component = Base.extend({
    data() {
        return {
            params: {
                active: null,
                message: '',
                sortBy: 'id',
                orderBy: 'desc',
                page: 1,
                name: '',

            },

            notifications: {
                data: [] as Array<INotification>,
                meta: {} as IPagination | any,
            } as INotificationsResponse,
        };
    },
    mounted() {
        this.getNotifications();
    },
    methods: {
        getNotifications(page: number = 1): void {
            this.params.page = page;

            this.get<INotificationsResponse>('notifications', {
                params: this.params,
            }).then(({ data }) => {
                this.notifications = data;
            });
        },

    },
});

export default component;
